import React, { useState, useEffect, useMemo, useCallback } from 'react';
import styled, { css } from 'styled-components';
import { spacing } from '@naf/theme';
import { nafColor } from '@nafcore/theme';
import { Text, TextVariant } from '@naf/text';
import { Button } from '@naf/button';
import axios from 'axios';
import { Newsfeed, NewsfeedSection } from '../../../../../types/NewsfeedBlockType';
import useSelector from '../../../redux/typedHooks';
import BlockContent from '../../block-content/BlockContent';
import EmptySvg from '../../../../assets/images/EmptyIllustration.svg?url';
import { getRelativeDate } from './QnaFeedBlock/QnaList';
import { RelatedContentList } from './QnaFeedBlock/QnaList/RelatedContentList';

interface Props {
  block: NewsfeedSection;
}

const REFETCH_INTERVAL = 30000;
const FETCH_OLDER_NEWS_COUNT = 5;

export const NewsfeedBlock: React.FC<Props> = ({ block }) => {
  const { title: blockTitle, ingress, _id, feedLength, isFinished } = block;
  const { apimBaseUrl, apimNafNoApi, apimContentHub } = useSelector((state) => state.application);
  const [newsfeed, setNewsfeed] = useState<Newsfeed[]>(block?.newsfeed || []);
  const [time, setTime] = useState<Date>(new Date());

  const [displayedFeedLength, setDisplayedFeedLength] = useState(feedLength || 0);
  const [latestDisplayPostIndex, setLatestDisplayedPostIndex] = useState<number>(block.newsfeed?.length || 0);

  const fetchData = async () => {
    if (isFinished) return;
    const formatedTime = new Date(time).toISOString();

    const res = await axios.get<NewsfeedSection[]>(
      `${apimBaseUrl}/${apimNafNoApi}/newsfeed/${_id}?timestamp=${formatedTime}`,
      { headers: { 'Ocp-Apim-Subscription-Key': apimContentHub } },
    );
    if (res.data.length === 1) {
      const newItems = res.data[0].newsfeed ? res.data[0].newsfeed.map((post) => ({ ...post, isNew: true })) : [];
      const filtered = [...newsfeed.map((post) => ({ ...post, isNew: false })), ...newItems].filter(
        (post, i, self) => i === self.findIndex((t) => t._key === post._key),
      );
      if (newItems.length > 0) {
        setNewsfeed(filtered);
      }
    }
  };

  const fetchOlder = async () => {
    const res = await axios.get<NewsfeedSection[]>(
      `${apimBaseUrl}/${apimNafNoApi}/newsfeed/${_id}/older?start=${latestDisplayPostIndex}&end=${
        latestDisplayPostIndex + FETCH_OLDER_NEWS_COUNT
      }`,
      { headers: { 'Ocp-Apim-Subscription-Key': apimContentHub } },
    );
    if (res.data.length >= 1) {
      const olderPosts = res.data[0].newsfeed ? res.data[0].newsfeed : [];
      const updated = [...olderPosts, ...newsfeed];
      setNewsfeed(updated);
      setLatestDisplayedPostIndex(updated.length);
    }
  };

  useEffect(() => {
    const timer = setInterval(fetchData, REFETCH_INTERVAL);
    return () => {
      clearInterval(timer);
    };
  });

  const feed = useMemo(
    () =>
      newsfeed
        .filter(({ timestamp }) => new Date(timestamp).getTime() < time.getTime())
        .sort((a, b) => new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime())
        .map(({ timestamp, title, body, _key, relatedContent, isNew }) => (
          <div key={_key}>
            <Top timestamp={timestamp} isNew={isNew} />
            <NewsfeedItem
              _key={_key}
              title={title}
              body={body}
              timestamp={timestamp}
              relatedContent={relatedContent}
              isNew={isNew}
            />
          </div>
        )),
    [newsfeed, time],
  );

  const updateDisplayedPosts = useCallback(() => {
    const newPostsCount = newsfeed.filter((post) => post.isNew).length;

    setDisplayedFeedLength(displayedFeedLength + newPostsCount);
    setLatestDisplayedPostIndex(latestDisplayPostIndex + newPostsCount);

    setTime(new Date());
  }, [newsfeed, displayedFeedLength, latestDisplayPostIndex]);

  const updateButton = useMemo(
    () =>
      newsfeed.some((post) => new Date(post.timestamp).getTime() > time.getTime()) && (
        <ButtonContainer>
          <Button variant="secondary" onClick={updateDisplayedPosts}>
            Last inn nye
          </Button>
        </ButtonContainer>
      ),
    [newsfeed, time, updateDisplayedPosts],
  );

  return (
    <div>
      <HeadContainer>
        <HeaderText variant={TextVariant.Header1}>{blockTitle}</HeaderText>
        {ingress && (
          <IngressContainer>
            <BlockContent value={ingress} />
          </IngressContainer>
        )}
        <HRLine />
      </HeadContainer>
      {updateButton}
      {feed}
      {displayedFeedLength > 0 ? (
        <>
          <Bottom>
            <Button
              disabled={displayedFeedLength === latestDisplayPostIndex}
              variant="secondary"
              onClick={() => fetchOlder()}
            >
              Vis flere
            </Button>
            <span>
              Viser {latestDisplayPostIndex} av {displayedFeedLength}
            </span>
          </Bottom>
          <BottomHR />
        </>
      ) : (
        <>
          <EmptyTop />
          <EmptyFeedItem isNew={false}>
            <Text variant={TextVariant.Header2}>Det er ingen oppføringer i denne nyhetsstrømmen enda</Text>
            <img src={EmptySvg} alt="Vi fant ingen ruter" />
          </EmptyFeedItem>
        </>
      )}
    </div>
  );
};

const NewsfeedItem: React.FC<Newsfeed> = (props) => {
  const { title, body, relatedContent, isNew } = props;

  return useMemo(
    () => (
      <News isNew={isNew}>
        <Text variant={TextVariant.Header2}>{title}</Text>
        <BlockContent value={body} />
        {relatedContent && <RelatedContentList relatedContent={relatedContent} />}
      </News>
    ),
    [isNew, title, body, relatedContent],
  );
};

const Top = ({ timestamp, isNew }: { timestamp: string; isNew?: boolean }) => (
  <StyledTop>
    <Dot $color={nafColor.primary.park} />
    <NewsHR size="small" isNew={isNew} />
    <SubtleText variant={TextVariant.Tiny}>{getRelativeDate(timestamp)}</SubtleText>
    <NewsHR size="large" isNew={isNew} />
  </StyledTop>
);

const EmptyTop = () => (
  <StyledTop>
    <Dot $color={nafColor.neutral.neutral3} />
    <EmptyHR />
  </StyledTop>
);

export const HeadContainer = styled.div`
  display: flex;
  flex-flow: column nowrap;
  margin-left: calc(${spacing.space12} + 2px);
`;

const HeaderText = styled(Text)`
  margin: 0;
  font-size: 2rem;
  line-height: 40px;
`;

const IngressContainer = styled.div`
  margin-top: ${spacing.space32};
  margin-bottom: 0;

  & * {
    font-size: 1.25rem;
    line-height: 32px;
    margin-top: 0;
    font-style: italic;
  }

  & > * {
    margin: 0;
  }

  & > * + * {
    margin-top: 1em;
  }
`;

const StyledTop = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
  > p {
    margin: 0 ${spacing.space8};
  }
`;

const ButtonContainer = styled.div`
  margin-bottom: ${spacing.space24};
  text-align: center;
`;

const NewsHR = styled.hr<{ isNew?: boolean; size: 'small' | 'large' }>`
  border: 0;
  margin: 0 ${spacing.space4};
  border-top: 1px solid ${({ isNew }) => (isNew ? nafColor.primary.park : nafColor.neutral.neutral3)};
  ${({ size }) =>
    size === 'large'
      ? css`
          flex-grow: 1;
        `
      : css`
          width: 16px;
        `}
`;

const EmptyHR = styled.hr`
  border-top: 1px solid ${nafColor.neutral.neutral3};
  margin: 0 ${spacing.space4};
  width: 100%;
`;

const HRLine = styled.hr`
  border: 0;
  border-top: 3px solid ${nafColor.signature.yellow};
  width: 96px;
  margin: ${spacing.space40} 0 ${spacing.space32} 0;
  color: ${nafColor.signature.yellow};
`;

const BottomHR = styled(HRLine)`
  margin-left: calc(${spacing.space12} + 2px);
  margin-top: ${spacing.space32};
  margin-bottom: 0;
`;

const News = styled.div<{ isNew?: boolean }>`
  border-left: 1px solid ${({ isNew }) => (isNew ? nafColor.primary.park : nafColor.neutral.neutral3)};
  padding: ${spacing.space12} 0 ${spacing.space24} ${spacing.space12};

  > :first-child {
    margin-top: 0;
  }

  > :last-child {
    margin-bottom: 0;
  }

  > div > figure {
    margin: 0;
  }

  > div > figure + figure {
    margin: 0;
    margin-top: ${spacing.space24};
  }
`;

const Dot = styled.div<{ $color: string }>`
  border-radius: 9999px;
  margin-left: -5px;
  min-width: 11px;
  max-width: 11px;
  height: 11px;
  background-color: ${({ $color }) => $color};
`;

const Bottom = styled.div`
  display: flex;
  align-items: center;
  margin-top: ${spacing.space32};
  margin-left: calc(${spacing.space12} + 2px);

  > span {
    margin-left: ${spacing.space24};
  }
`;

const SubtleText = styled(Text)`
  color: ${nafColor.neutral.neutral4};
`;

const EmptyFeedItem = styled(News)`
  color: ${nafColor.neutral.neutral4};
`;
